import store from "@/store";
import axios from "axios";

//TODO: turn this into system variable
//https://andrewp.online/leaguemode/api/admin/
//http://localhost:6969/leaguemode/api/admin/
const baseUri = "https://andrewp.online/leaguemode/api/admin/"
export type FeatureRequest = {Title: string, Requester: string, Description: string, CreatedAt: string}

class LeagueModeService {
  async GetUserWorldMapData(): Promise<Array<{ CountryCode: string, Count: number }>> {
    return await axios.get(`${baseUri}users`, { headers: authHeader() })
      .then((res: { data: { Response: any; }; }) => {
        return res.data.Response;
      })
      .catch((e: { message: any; }) => {
        store.dispatch("addError", e.message);
      });
  }

  async GetUserUSAMapData(): Promise<Array<{ Region: string, Count: number }>> {
    return await axios.get(`${baseUri}us_users`, { headers: authHeader() })
      .then((res: { data: { Response: any; }; }) => {
        return res.data.Response;
      })
      .catch((e: { message: any; }) => {
        store.dispatch("addError", e.message);
      });
  }

  async GetFeatureRequests(): Promise<Array<FeatureRequest>> {
    return await axios.get(`${baseUri}feature_requests`, { headers: authHeader() })
      .then((res: { data: { Response: any; }; }) => {
        return res.data.Response;
      })
      .catch((e: { message: any; }) => {
        store.dispatch("addError", e.message);
      });
  }
}

function authHeader() {
  const token = localStorage.getItem("token");

  if (token) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

export default new LeagueModeService();