import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import LeagueModeService, { FeatureRequest } from '@/services/LeagueModeService';
import { onMounted, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LeagueModeAdminComponent',
  setup(__props) {

const totalWorld = ref<number>(0);
const features = ref<Array<FeatureRequest>>();

onMounted(async () => {
    const google = window as any
    google.google.charts.load('current', { 'packages': ['geochart'] });
    google.google.charts.setOnLoadCallback(drawWorldMap);
    google.google.charts.setOnLoadCallback(drawUSMap);

    async function drawWorldMap() {
        let userData = await LeagueModeService.GetUserWorldMapData();
        let graphData: Array<Array<any>> = [];
        graphData.push(['Country', 'Popularity']);
        userData.map(x => {
            let temp = [];
            temp.push(x.CountryCode);
            temp.push(x.Count);
            totalWorld.value += x.Count;
            graphData.push(temp);
        })

        var data = google.google.visualization.arrayToDataTable(graphData);

        var options = { responsive: true };

        var chart = new google.google.visualization.GeoChart(document.getElementById('world_div'));

        chart.draw(data, options);
    }

    async function drawUSMap() {
        let userData = await LeagueModeService.GetUserUSAMapData();
        let graphData: Array<Array<any>> = [];
        graphData.push(['State', 'Popularity']);
        userData.map(x => {
            let temp = [];
            temp.push(`US-${x.Region}`);
            temp.push(x.Count);
            graphData.push(temp);
        })

        var data = google.google.visualization.arrayToDataTable(graphData);

        var options = { region: "US", resolution: "provinces", responsive: true };

        var chart = new google.google.visualization.GeoChart(document.getElementById('us_div'));

        chart.draw(data, options);
    }

    features.value = await LeagueModeService.GetFeatureRequests();
})

const headers = [
    {
        title: 'Title',
        sortable: false,
        key: 'Title',
    },
    { title: 'Requester', key: 'Requester', sortable: false  },
    { title: 'Request Date', key: 'CreatedAt', sortable: true },
    { title: 'Actions', key: 'actions', sortable: false },
]
const showDialog = ref<boolean>();
const selectedFeature = ref<FeatureRequest>();

function viewDetails(feature: FeatureRequest) {
    selectedFeature.value = feature;
    showDialog.value = true;
}


return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_data_table = _resolveComponent("v-data-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_badge, {
      style: {"margin-top":"2rem"},
      content: totalWorld.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_icon, {
          color: "grey lighten-1",
          icon: "fas fa-user-circle",
          large: ""
        })
      ]),
      _: 1
    }, 8, ["content"]),
    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
    _cache[2] || (_cache[2] = _createElementVNode("div", { id: "world_div" }, null, -1)),
    _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
    _cache[4] || (_cache[4] = _createElementVNode("div", { id: "us_div" }, null, -1)),
    _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
    _createVNode(_component_v_data_table, {
      headers: headers,
      items: features.value,
      "sort-by": [{ key: 'requestDate', order: 'desc' }]
    }, {
      top: _withCtx(() => [
        _createVNode(_component_v_toolbar, { flat: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_dialog, {
              modelValue: showDialog.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showDialog).value = $event)),
              "max-width": "500px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "text-h5" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(selectedFeature.value?.Description), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      [`item.actions`]: _withCtx(({ item }) => [
        _createVNode(_component_v_icon, {
          size: "small",
          onClick: ($event: any) => (viewDetails(item)),
          icon: "fas fa-info-circle"
        }, null, 8, ["onClick"])
      ]),
      _: 2
    }, 1032, ["items"])
  ], 64))
}
}

})