<script setup lang="ts">
import LeagueModeService, { FeatureRequest } from '@/services/LeagueModeService';
import { onMounted, ref } from 'vue';

const totalWorld = ref<number>(0);
const features = ref<Array<FeatureRequest>>();

onMounted(async () => {
    const google = window as any
    google.google.charts.load('current', { 'packages': ['geochart'] });
    google.google.charts.setOnLoadCallback(drawWorldMap);
    google.google.charts.setOnLoadCallback(drawUSMap);

    async function drawWorldMap() {
        let userData = await LeagueModeService.GetUserWorldMapData();
        let graphData: Array<Array<any>> = [];
        graphData.push(['Country', 'Popularity']);
        userData.map(x => {
            let temp = [];
            temp.push(x.CountryCode);
            temp.push(x.Count);
            totalWorld.value += x.Count;
            graphData.push(temp);
        })

        var data = google.google.visualization.arrayToDataTable(graphData);

        var options = { responsive: true };

        var chart = new google.google.visualization.GeoChart(document.getElementById('world_div'));

        chart.draw(data, options);
    }

    async function drawUSMap() {
        let userData = await LeagueModeService.GetUserUSAMapData();
        let graphData: Array<Array<any>> = [];
        graphData.push(['State', 'Popularity']);
        userData.map(x => {
            let temp = [];
            temp.push(`US-${x.Region}`);
            temp.push(x.Count);
            graphData.push(temp);
        })

        var data = google.google.visualization.arrayToDataTable(graphData);

        var options = { region: "US", resolution: "provinces", responsive: true };

        var chart = new google.google.visualization.GeoChart(document.getElementById('us_div'));

        chart.draw(data, options);
    }

    features.value = await LeagueModeService.GetFeatureRequests();
})

const headers = [
    {
        title: 'Title',
        sortable: false,
        key: 'Title',
    },
    { title: 'Requester', key: 'Requester', sortable: false  },
    { title: 'Request Date', key: 'CreatedAt', sortable: true },
    { title: 'Actions', key: 'actions', sortable: false },
]
const showDialog = ref<boolean>();
const selectedFeature = ref<FeatureRequest>();

function viewDetails(feature: FeatureRequest) {
    selectedFeature.value = feature;
    showDialog.value = true;
}

</script>


<template>
    <v-badge style="margin-top: 2rem;" :content="totalWorld">
        <v-icon color="grey lighten-1" icon="fas fa-user-circle" large></v-icon>
    </v-badge>
    <br />
    <div id="world_div"></div>
    <br />
    <div id="us_div"></div>
    <br />

    <v-data-table :headers="headers" :items="features" :sort-by="[{ key: 'requestDate', order: 'desc' }]">
        <template v-slot:top>
            <v-toolbar flat>
                <v-dialog v-model="showDialog" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">{{ selectedFeature?.Description }}</v-card-title>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon size="small" @click="viewDetails(item)" icon="fas fa-info-circle"></v-icon>
        </template>
    </v-data-table>
</template>

<style scoped></style>
